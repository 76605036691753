.text-animate {
  display: inline-block;
  opacity: 0;
  animation: bounceIn;
  animation-duration: 1s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
  min-width: 10px;
}

