.projects_spacer{
    min-height: 400px;
    max-width: 1px;
}

.container.projects-page {
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 35%;
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;
    background: transparent; 
    pointer-events: none;

    h1 {
      font-size: 53px;
      font-family: 'Coolvetica';
      color: #d200f3;
      font-weight: 400;
      margin-top: 0;
      position: relative;
      margin-bottom: 40px;
      left: -10px;

      &:before {
        content: '<h1>';
        font-family: 'La Belle Aurore', cursive;
        color: #d200f3;
        font-size: 18px;
        position: absolute;
        margin-top: -10px;
        left: -10px;
        opacity: 0.6;
        line-height: 18px;
      }

      &:after {
        content: '<h1/>';
        font-family: 'La Belle Aurore', cursive;
        color: #d200f3;
        font-size: 18px;
        line-height: 18px;
        position: absolute;
        left: -30px;
        bottom: -20px;
        margin-left: 20px;
        opacity: 0.6;
      }
    }

    p {
      font-size: 17px;
      color: #fff;
      font-family: Montserrat;
      font-weight: 300;
      max-width: fit-content;
      animation: pulse 1s;
      &:nth-of-type(1) {
        animation-delay: 1.1s;
      }
      &:nth-of-type(2) {
        animation-delay: 1.2s;
      }
      &:nth-of-type(3) {
        animation-delay: 1.3s;
      }
    }
  }

  
}
.projects-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 170px;
    font-family: 'Montserrat';
    margin-left: 150px;
  
    .project-card {
      background-color: #2b2b2b;
      border-radius: 15px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      padding: 10px 20px 20px 20px;
      color: #fff;
      text-align: left; 
      display: flex; 
      justify-content: space-between;
      align-items: center;
      transition: transform 0.3s ease-in-out;
      flex: 1 1 30%;
      max-width: 28%;
      gap: 30px;
  
      &:hover {
        transform: scale(1.05);
      }
  
      .project-info {
        flex: 1;
      }
  
      img {
        max-width: 150px; 
        max-height: 150px; 
        
      }
  
  
      p {
        margin-bottom: 15px;
        font-size: 1.3rem;
        font-family: 'Montserrat';
        color: #cbcbcb;
      }
      a {
      display: inline-block; 
      background-color: #9600ad; 
      color: #fff; 
      font-family: 'Quan Pro';
      text-decoration: none;
      padding: 4px 6px; 
      font-size: 14px;
      transition: background-color 0.3s ease-in-out;

      &:hover {
        background-color: #6a007b; 
      }
    }
  }
}
  